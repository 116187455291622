/** -1 */
export const NUMERIC_MAINASU1 = -1;

/** 0 */
export const NUMERIC_0 = 0;

/** 1 */
export const NUMERIC_1 = 1;

/** 2 */
export const NUMERIC_2 = 2;

/** 3 */
export const NUMERIC_3 = 3;

/** 4 */
export const NUMERIC_4 = 4;

/** 5 */
export const NUMERIC_5 = 5;

/** 6 */
export const NUMERIC_6 = 6;

/** 7 */
export const NUMERIC_7 = 7;

/** 8 */
export const NUMERIC_8 = 8;

/** 9 */
export const NUMERIC_9 = 9;

/** 10 */
export const NUMERIC_10 = 10;

/** 11 */
export const NUMERIC_11 = 11;

/** 12 */
export const NUMERIC_12 = 12;

/** 13 */
export const NUMERIC_13 = 13;

/** 14 */
export const NUMERIC_14 = 14;

/** 15 */
export const NUMERIC_15 = 15;

/** 16 */
export const NUMERIC_16 = 16;

/** 17 */
export const NUMERIC_17 = 17;

/** 18 */
export const NUMERIC_18 = 18;

/** 19 */
export const NUMERIC_19 = 19;

/** 20 */
export const NUMERIC_20 = 20;

/** 21 */
export const NUMERIC_21 = 21;

/** 22 */
export const NUMERIC_22 = 22;

/** 23 */
export const NUMERIC_23 = 23;

/** 24 */
export const NUMERIC_24 = 24;

/** 25 */
export const NUMERIC_25 = 25;

/** 26 */
export const NUMERIC_26 = 26;

/** 27 */
export const NUMERIC_27 = 27;

/** 28 */
export const NUMERIC_28 = 28;

/** 29 */
export const NUMERIC_29 = 29;

/** 30 */
export const NUMERIC_30 = 30;

/** 40 */
export const NUMERIC_40 = 40;

/** 41 */
export const NUMERIC_41 = 41;

/** 42 */
export const NUMERIC_42 = 42;

/** 99 */
export const NUMERIC_99 = 99;

/** PLP内部リンク 求人何件目に表示か */
export const PLPLINKLIST_JOB_COUNT = 3;

/** PLP内部リンク表示位置（JobSearchListページ上部） */
export const PLPLINKLIST_JOBSEARCHLIST_UPPER = 1;

/** PLP内部リンク表示位置（JobSearchListページ下部） */
export const PLPLINKLIST_JOBSEARCHLIST_UNDER = 2;

/** ● ● ●サイト区分 10:DODA */
export const SITEID_DODA = String(NUMERIC_10);

/** SEO遷移図の画面パターン分けの返り値専用定数群 */
/** 1・サイト区分が10：DODAの時、URLが詳細検索結果の場合、左パネルの種別＝エリアドリルダウンの場合 */
export const DODA_SEARCH_RESULT_LEFTPANEL_AR = NUMERIC_1;

/** 2・サイト区分が10：DODAの時、URLが詳細検索結果の場合、左パネルの種別＝業種ドリルダウンの場合 */
export const DODA_SEARCH_RESULT_LEFTPANEL_IND = NUMERIC_2;

/** 3・サイト区分が10：DODAの時、URLが詳細検索結果の場合、左パネルの種別＝職種ドリルダウンの場合 */
export const DODA_SEARCH_RESULT_LEFTPANEL_OC = NUMERIC_3;

/** 4・サイト区分が10：DODAの時、URLが詳細検索結果の場合 */
export const DODA_SEARCH_RESULT_LEFTPANEL_NO = NUMERIC_4;

/** 5・サイト区分が10：DODAの時、URLが求人原稿の場合 */
export const DODA_SEARCH_RESULT_DETAIL = NUMERIC_5;

/** 7・サイト区分が10：DODAの時、URLが検索トップの場合 */
export const DODA_SEARCH_TOP = NUMERIC_7;

/** 11・サイト区分が10：DODA、URLが検索条件保存の場合 */
export const DODA_SEARCH_CONDITION_SAVE = NUMERIC_11;

/** 12・サイト区分が10：DODAの時、URLがセミナー一覧（企業別）、セミナー一覧（一覧） の場合 */
export const DODA_SEMINAR_LIST = NUMERIC_12;

/** 13・サイト区分が10：DODAの時、URLがセミナー詳細_会社説明会（有料）の場合 */
export const DODA_SEMINAR_DETAIL_INFO_SESSION = NUMERIC_13;

/** 14・サイト区分が10：DODAの時、URLがセミナー詳細_相談会（無料）の場合 */
export const DODA_SEMINAR_DETAIL_CONSULTANT = NUMERIC_14;

/** 16・サイト区分が10：DODAの時、URLが新着求人の場合 */
export const DODA_NEW_OFFER = NUMERIC_16;

/** 17・サイト区分が10：DODAの時、URLが求人特集の場合 */
export const DODA_OFFER_SP = NUMERIC_17;

/** 18・サイト区分が10：DODAの時、URLが最近見た求人の履歴の場合 */
export const DODA_LATEST_CHECK_OFFER = NUMERIC_18;

/** サイト区分が10：DODAの時、URLが地域別路線一覧画面の場合 */
export const DODA_ROUTE_LIST = 28001;

/** サイト区分が10：DODAの時、URLが路線別駅名一覧画面の場合 */
export const DODA_STATION_LIST = 28002;

/** 27・サイト区分が10：DODAの時、URLが気になる履歴をもとにしたおすすめの求人一覧画面の場合 */
export const DODA_INTENTIONJOB_LIST = NUMERIC_27;

/** 28・サイト区分が10：DODAの時、URLが資格・経歴スキル求人一覧画面の場合 */
export const DODA_CAREERRECOMMENDJOB_LIST = NUMERIC_28;

/** 29・サイト区分が10：DODAの時、URLが全資格一覧画面の場合 */
export const DODA_QUALIFICATION_LIST = NUMERIC_29;

/** ● ● ●左パネル種別 3:職種ドリルダウン */
export const DRILL_DOWN_OC = String(NUMERIC_3);

/** ● ● ●左パネル種別 1:エリアドリルダウン */
export const DRILL_DOWN_AR = String(NUMERIC_1);

/** ● ● ●左パネル種別 2:業種ドリルダウン */
export const DRILL_DOWN_IND = String(NUMERIC_2);

/** ● ● ●新着求人区分（１：新着求人_業種） */
export const KYUJIN_NEW_IND = String(NUMERIC_1);

/** ● ● ●新着求人区分（１：新着求人_職種） */
export const KYUJIN_NEW_OC = String(NUMERIC_2);

/** ● ● ●新着求人区分（１：新着求人_エリア） */
export const KYUJIN_NEW_AREA = String(NUMERIC_3);

/** ● ● ●イベント種別（１：会社説明会（有料）） */
export const EVENT_EXPLAN = String(NUMERIC_1);

/** ● ● ●イベント種別（２：相談会（無料）） */
export const EVENT_CONSULT = String(NUMERIC_2);

/** ● ● ●1 :デフォルト値を表示 */
export const DEFAULTFLG_FLG = String(NUMERIC_1);

/** ● ● ● ルール設定フラグ */
export const WRRST_FLG = NUMERIC_1;

/** ● ● ● ルール設定フラグ(設定) */
export const WRRST_FLG_SET = NUMERIC_2;

export const SURASHU = '/';
export const SURASHU_N = '／';
export const ASTERISK = '*';

/** ● ● ●符号 */
export const PLUS = '+';

/** ● ● ●符号 */
export const STR_NO = 'の';

/** 文字列「ほか」 */
export const STR_ETC = 'ほか';

/** ● ● ●符号 */
export const SPACE = ' ';

/** ● ● ●符号 */
export const COMMA = ',';

/** ● ● ●符号 */
export const COMMA_URL_ENCODE = '%2C';

/** ● ● ●符号 */
export const MARK = '。';

/** ● ● ●符号 */
export const COMMA_N = '、';

/** エリア */
export const AREA = 'エリア';

/** 勤務地 javaではNIMU */
export const WORK_LOCATION = '勤務地';

/** 区切り文字（全角コロン） */
export const SEPARATED_FULL_COLON = '：';

/** 記号（（ ) */
export const SIGN_FULLSIZE_KAKO_ST = '（';

/** 記号（ ）) */
export const SIGN_FULLSIZE_KAKO_ED = '）';

/** ●●●タイトル */
// <$社名$>／<$新着職種$>
export const COMPANY_DODA_PR_TITLE_NM =
  'のPick up!情報 － 転職ならdoda（デューダ）';

/** ●●●タイトル */
export const COMPANY_DODA_JD_TITLE_NM =
  'の求人情報 － 転職ならdoda（デューダ）';

/** ●●●タイトル */
export const COMPANY_DODA_RV_TITLE_NM =
  'の口コミ情報 － 転職ならdoda（デューダ）';

/** ●●●タイトル */
export const DODA_SEARCH_TITLE_NM =
  '求人検索 詳細条件から探す － 転職ならdoda（デューダ）';

/** ●●●タイトル */
export const SEARCH_SAVE_TITLE_NM =
  '求人情報の検索結果一覧 - 求人・求人情報の検索ならdoda（デューダ）';

/** ●●●タイトル */
export const SEMINAR_LIST_TITLE_NM =
  '会社説明会、セミナー、イベント情報の検索 - 転職ならdoda（デューダ）';

/** ●●●タイトル */
// 資格・経歴スキル求人一覧画面で使用
export const CAREERRECOMMENDJOB_LIST_TITLE_NM =
  '%s 資格保有者が興味関心のある%s転職・求人・中途採用情報【doda（デューダ）】';

/** ●●●タイトル */
// 資格・経歴スキル求人一覧画面（2ページ目以降）で使用
export const CAREERRECOMMENDJOB_LIST_TITLE_NM_2PAGE =
  '%s%s 資格保有者が興味関心のある%s転職・求人・中途採用情報【doda（デューダ）】';

/** ●●●タイトル */
// <$説明会名$> または <$相談会名$>
export const SEMINAR_DETAIL_TITLE_NM = ' - 転職ならdoda（デューダ）';

/** ●●●タイトル */
export const OC_NEW_TITLE_NM = '新着求人情報 - 転職ならdoda（デューダ）';

/**
 * ●●●タイトル
 * <$求人特集名$>あり
 */
export const KYUJIN_FEATURE_TITLE_NM = '転職・求人情報 - doda';

/** ●●●タイトル */
export const HISTORY_TITLE_NM = '最近見た求人 | 転職ならdoda（デューダ）';

/** ●●●Meta_Des */
export const COMPANY_DODA_DES_NM =
  '求人・求人情報ならdoda（デューダ）。仕事内容など詳しい採用情報や職場の雰囲気が伝わる情報が満載。';

/** ●●●Meta_Des pickup!*/
export const COMPANY_DODA_PR_DES_NM =
  '求人・pickup!情報ならdoda（デューダ）。仕事内容など詳しい採用情報や職場の雰囲気が伝わる情報が満載。';

/** ●●●Meta_Des */
export const COMPANY_DODA_RV_TAB_DES_NM =
  '口コミ情報ならdoda（デューダ）。仕事内容など詳しい採用情報や職場の雰囲気が伝わる情報が満載。';

/** ●●●Meta_Des */
export const DODA_SEARCH_DES_NM =
  '求人情報の検索。職種、業種、エリアなどさまざまな条件から、大手・優良企業を中心に豊富な求人情報から最適な求人を求人検索できます。';

/** ●●●Meta_Des */
export const SEMINAR_LIST_DES_NM =
  '会社説明会／セミナー／イベント情報ならdoda（デューダ）。仕事内容や応募資格など詳しい採用情報や職場の雰囲気が伝わる情報が満載。';

/** ●●●Meta_Des */
export const SEMINAR_DETAIL_DES_NM =
  '。会社説明会／セミナー／イベント情報ならdoda（デューダ）。仕事内容や応募資格など詳しい採用情報や職場の雰囲気が伝わる情報が満載。';

/** ●●●Meta_Des */
export const NEW_DES_NM =
  'dodaの新着・更新求人情報。大手・優良企業の新着求人を毎週更新！あなたの転職に最適な求人はdoda（デューダ）で検索。';

/** ●●●Meta_Des */
export const KYUJIN_FEATURE_DESE_NM =
  '転職・求人情報の検索ならdoda（デューダ）。';

/** ●●●Meta_Des 2ページ以降 */
export const KYUJIN_FEATURE_DES_NM_PAGE =
  '%sページ目(%s-%s件)の情報。%s。転職・求人情報の検索ならdoda（デューダ）。';

/** ●meta・keyword要素固定文字 */
export const KEY_NM = '転職,求人,求人情報';

/** ●meta・keyword要素固定文字 pickup!*/
export const KEY_PR_NM = '転職,求人,pickup!情報';

/** ●meta・keyword要素固定文字 口コミ */
export const KEY_RV = '口コミ';

/** ●meta・keyword要素追加文字 %s＝項目名 */
export const KEY_NM_DETAIL = '%s,転職,求人,求人情報,doda';

/** ●meta・keyword要素固定文字:２ページ以降 %s＝%sページ,%s件-%s件 */
export const KEY_NM_2PAGE = '%sページ,%s件-%s件,転職,求人,求人情報';

/** ●meta・keyword要素追加文字:２ページ以降 %s＝%sページ,%s件-%s件 %s＝項目名 */
export const KEY_NM_DETAIL_2PAGE =
  '%sページ,%s件-%s件,%s,転職,求人,求人情報,doda';

/** ●meta・keyword要素追加文字:２ページ以降 %s＝%sページ,%s件-%s件 %s＝項目名 */
export const KEY_NM_DETAIL_MASTER_2PAGE = '%sページ,%s件-%s件,%s';

/** ●meta・keyword要素固定文字:２ページ以降 %s＝%sページ,%s件-%s件,%s=特集名 */
export const KEY_NM_FEATURE_2PAGE = '%sページ,%s件-%s件,%s,転職,求人,求人情報';

/** 希望年収HA */
export const STR_HA_NO_SELECT = '0';

/** STR_L */
export const STR_L = 'L';

/** STR_M */
export const STR_M = 'M';

/** STR_S */
export const STR_S = 'S';

/** 近隣検索用エリア文言（都道府県） */
export const NEAR_AREA_TEXT_PR = '都道府県';

/** 近隣検索用エリア文言（市区町村） */
export const NEAR_AREA_TEXT_CIWA = '市区町村';

/** 近隣検索用エリア文言（駅） */
export const NEAR_AREA_TEXT_ST = '駅';

/** 近隣検索用エリア文言（近隣） */
export const NEAR_AREA_TEXT_NEAR = '近隣';

/** ●title要素固定文字 %s＝ｎページ｜ */
export const TITLE_NM_PAGE = 'ページ｜';

/** ●title要素固定文字 %s＝ｎページ｜ */
export const TITLE_NM_DODA = '%s転職・求人情報- doda';

/** ●title要素追加文字 %s＝ｎページ｜ %s＝項目名 */
export const TITLE_NM_DODA_DETAIL = '%s%sの転職・求人情報- doda';

/** ●title要素追加文字 %s＝ｎページ｜ %s＝項目名 */
export const TITLE_NM_DODA_DETAIL_WITH_COUNT =
  '%s%s%s%sの転職・求人・中途採用情報│doda（デューダ）';

/** ●meta・description要素固定文字 */
export const DES_NM_DODA =
  '転職・求人情報ページ。パーソルキャリアが運営する転職サイトのdoda（デューダ）では、大手・優良企業を中心に豊富な求人情報を掲載中。' +
  'エージェントサービスでは、キャリアアドバイザーが、非公開求人のご紹介など、あなたの転職を成功に導くお手伝いをします。';

/** ●meta・description要素追加文字 %s＝項目名 */
export const DES_NM_DODA_DETAIL =
  '%sの転職・求人情報ページ。パーソルキャリアが運営する転職サイトのdoda（デューダ）では、大手・優良企業を中心に豊富な求人情報を掲載中。' +
  'エージェントサービスでは、キャリアアドバイザーが、非公開求人のご紹介など、あなたの転職を成功に導くお手伝いをします。';

/** ●meta・description要素追加文字 %s＝項目名、%,d＝該当求人件数 */
export const DES_NM_DODA_DETAIL_WITH_COUNT =
  '%sの転職・求人情報ページ：公開求人数%s件。' +
  '「完全週休2日制」「職種・業種未経験歓迎」など、条件にあった検索ができます。' +
  'パーソルキャリアが運営するdoda（デューダ）は、大手・優良企業を中心に豊富な求人情報を掲載中。';

/** ●meta・description要素追加文字 %s＝項目名、%,d＝該当求人件数（資格・経歴スキル求人一覧画面） */
export const DES_NM_DODA_CAREERJOBLIST_DETAIL_WITH_COUNT =
  '%sの資格保有者が興味関心のある%s転職・求人情報一覧ページ：公開求人数%s件。' +
  '「完全週休2日制」「職種・業種未経験歓迎」など、条件にあった検索ができます。' +
  'パーソルキャリアが運営するdoda（デューダ）は、大手・優良企業を中心に豊富な求人情報を掲載中。';

/** ●meta・description要素固定文字:２ページ以降 %s＝%sページ目（%s-%s件） */
export const DES_NM_2PAGE =
  '%sページ目（%s-%s件）の情報。検索結果%sページ目（%s-%s件）の情報を掲載。';

/** ●meta・description要素追加文字:２ページ以降 %s＝%sページ目（%s-%s件） %s＝項目名 */
export const DES_NM_DETAIL_2PAGE =
  '%sページ目（%s-%s件）の情報。%sの検索結果%sページ目（%s-%s件）の情報を掲載。';

/** ●meta・description要素追加文字:２ページ以降 %s＝%sページ目（%s-%s件） %s＝項目名（資格・経歴スキル求人一覧画面）  */
export const DES_NM_CAREERJOBLIST_DETAIL_2PAGE =
  '%sページ目（%s-%s件）の情報。%sの資格保有者が興味関心のある%s転職・求人情報一覧%sページ目（%s-%s件）の情報を掲載。';

/** ● ● ●符号 */
export const BLANK = '';

/** ● ● ●符号 */
export const LEFT_KAKO = '【';

/** ● ● ●符号 */
export const RIGHT_KAKO = '】';

/** ● ● ●符号 */
export const LESSER = '＜';

/** ● ● ●符号 */
export const GREATER = '＞';

/** oc */
export const STR_OC_EQUAL = 'oc=';

/** ar */
export const STR_AR_EQUAL = 'ar=';

/** pr */
export const STR_PR_EQUAL = 'pr=';

/** ci */
export const STR_CI_EQUAL = 'ci=';

/** wa */
export const STR_WA_EQUAL = 'wa=';

/** cr */
export const STR_CR_EQUAL = 'cr=';

/** rt */
export const STR_RT_EQUAL = 'rt=';

/** st */
export const STR_ST_EQUAL = 'st=';

/** qc */
export const STR_QC_EQUAL = 'qc=';

/** ind */
export const STR_IND_EQUAL = 'ind=';

/** こだわりop */
export const STR_OP_EQUAL_DODA = 'op=';

/** 希望年収ha */
export const STR_HA_EQUAL_DODA = 'ha=';

/** 駅 */
export const EKI = '駅';

/** 従業員数ne */
export const STR_NE_EQUAL_DODA = 'ne=';

/** 設立es */
export const STR_ES_EQUAL_DODA = 'es=';

/** 左パネル */
export const STR_PREBTN_EQUAL = 'preBtn=';

/** ボックス名 */
export const BOX_NAME_FORMAT = '%sの転職・求人情報を絞り込む （%s）';

/** ボックス名 （駅） */
export const BOX_NAME_FORMAT_EKI = '%sの転職・求人情報を探す（%s）';

/** ボックス名 （市区町村） */
export const BOX_NAME_FORMAT_CITY = '%sの駅から転職・求人情報を探す';

/** ボックス名 （市区町村） */
export const BOX_NAME_FORMAT_NARROW_DOWN = '%sの転職・求人情報を%sで絞り込む';

/** ボックス名 （こだわり条件） */
export const BOX_NAME_FORMAT_KODAWARI = 'こだわり条件から転職・求人情報を探す';

/** ボックス名 （こだわり条件） ※ほかの～で探す */
export const BOX_NAME_FORMAT_KODAWARI_OTHER =
  '%sの転職・求人情報をほかのこだわり条件で探す';

/** ボックス名 (条件指定なし) */
export const BOX_NAME_NO_QUERY = '転職・求人情報をこだわり条件で絞り込む';

/** ボックス名（都道府県） */
export const BOX_NAME_FORMAT_PR = '%sの都道府県から転職・求人情報を探す';

/** ボックス名（市区町村） */
export const BOX_NAME_FORMAT_CI = '%sの市区町村から転職・求人情報を探す';

/** ボックス名（資格） */
export const BOX_NAME_FORMAT_QC = '%sの資格で転職・求人情報を探す';

/** ボックス名（資格） */
export const BOX_NAME_FORMAT_QC_NARROW_DOWN =
  '%sの資格で転職・求人情報を絞り込む';

/** ボックス名（資格） */
export const BOX_NAME_FORMAT_QC_NARROW_DOWN_PR =
  '%sの転職・求人情報を都道府県で絞り込む';

/** ボックス名（資格） */
export const BOX_NAME_FORMAT_QC_NARROW_DOWN_CI =
  '%sエリアの資格で転職・求人情報を絞り込む';

/** ボックス名（その他） */
export const BOX_NAME_FORMAT_OTHER = 'ほかの%sで探す';

/** 業種 */
export const GYOSYU = '業種';

/** 業種大分類 */
export const GYOSYU_L = '業種大分類';

/** 業種小分類 */
export const GYOSYU_S = '業種小分類';

/** 職種 */
export const SYOKUSYU = '職種';

/** 職種大分類 */
export const SYOKUSYU_L = '職種大分類';

/** 職種中分類 */
export const SYOKUSYU_M = '職種中分類';

/** 職種小分類 */
export const SYOKUSYU_S = '職種小分類';

/** こだわり条件 */
export const KODAWARI_SEARCH = 'こだわり条件';

/** 社員の平均年齢 */
export const STAFF_AVG_AGE = '社員の平均年齢';

/** 年収 */
export const NENSHU = '年収';

/** 雇用形態 */
export const KOYOUKEITAI = '雇用形態';

/** 固定給 */
export const KOTEIKYU = '固定給';

/** リンクボックス表示タイトル (駅) */
export const NEAR_STATION = '付近';

/** 付近と判断する駅の数 */
export const NEAR_STATION_COUNT = 5;

/** 検索パラメータ種別 */
export const SEARCH_TYPE = {
  /** こだわり条件 */
  OP: 'OP',
  /** 年収 */
  HA: 'HA',
  /** 職種 */
  OC: 'OC',
  /** エリア */
  AR: 'AR',
  /** 都道府県 */
  PR: 'PR',
  /** 市区郡 */
  CI: 'CI',
  /** 政令指定都市の区 */
  WA: 'WA',
  /** 業種 */
  IND: 'IND',
  /** 従業員数 */
  NE: 'NE',
  /** 設立年 */
  ES: 'ES',
  /** 資格 */
  QC: 'QC',
} as const;

/** F028002_路線別駅名一覧画面：タイトル */
export const F028002_TITLE_NM =
  '%s（%s）の転職・求人情報 － 転職ならdoda（デューダ）';

/** F028002_路線別駅名一覧画面：キーワード */
export const F028002_KEYWORDS = `%s（%s）${COMMA}${KEY_NM}`;

/** F028002_路線別駅名一覧画面：Description */
export const F028002_DESCRIPTION =
  '%s（%s）の転職・求人情報ページ。' +
  'パーソルキャリアが運営する転職サイトのdoda（デューダ）では、大手・優良企業を中心に豊富な求人情報を掲載中。' +
  'エージェントサービスでは、キャリアアドバイザーが、非公開求人のご紹介など、あなたの転職を成功に導くお手伝いをします。';

export const BREADCRUMB_TOP_TEXT = '転職・求人doda（デューダ）トップ';

export const OCCUPATION_OTHER_TECHNICAL_POSITIONS = '0427';

export const OCCUPATION_TECHNICAL_WRITER = '0446';
