import { ValueOf } from '../utils/typeUtil';

/**
 * dodaサイトドメイン
 */
export const DODA_SITE_DOMAIN = {
  Production: 'https://doda.jp',
  development: 'http://localhost:3000',
  ST: 'https://test-st.doda.jp',
  Staging: 'https://test-staging.doda.jp',
  UAT: 'https://test-uat.doda.jp',
  TestEnv03: 'https://test-testenv03.doda.jp',
  TestEnv04: 'https://test-testenv04.doda.jp',
  TestEnv05: 'https://test-testenv05.doda.jp',
  TestEnv06: 'https://test-testenv06.doda.jp',
  TestEnv07: 'https://test-testenv07.doda.jp',
} as const;

/**
 * dodaサイトの画面URL
 */
export const DODA_SITE_URL = {
  jobSearchDetail: '/DodaFront/View/JobSearchDetail.action',
  jobSearchList: '/DodaFront/View/JobSearchList.action',
  jobSearchTop: '/DodaFront/View/JobSearchTop.action',
  recommendJobList: '/DodaFront/View/RecommendJobList.action',
  seminarList: '/DodaFront/View/SeminarList.action',
  seminarDetail: '/DodaFront/View/SeminarDetail.action',
  newJobList: '/DodaFront/View/NewJobList.action',
  jobHistoryList: '/DodaFront/View/JobHistoryList.action',
  routeList: '/DodaFront/View/RouteList.action',
  stationList: '/DodaFront/View/StationList.action',
  intentionJobList: '/DodaFront/View/IntentionJobList.action',
  careerRecommendJobList: '/DodaFront/View/CareerRecommendJobList.action',
  qualificationList: '/DodaFront/View/QualificationList/',
  featuredJobList: '/DodaFront/View/FeaturedJobList.action',
  matchJobList: '/DodaFront/View/MatchJobList.action',
  conditionChange: '/DodaFront/View/ConditionChange.action',
  loginScreen: '/dcfront/login/login/',
  logout: ' /dcfront/login/logout/',
  settings: ' /dcfront/mypage/mypageRegistSetting/',
  webResume: ' /dcfront/mypage/mypageWebResume/',
  logoutWebResume:
    '/dcfront/login/login/?url=%2Fdcfront%2Fmypage%2FmypageWebResume%2F',
  registration:
    '/dcfront/member/memberRegist/?entry_id=26&carry_id=5000000509&carry_class=k',
  savedConditionList: '/dcfront/condition/conditionList/',
  jobTrend: '/DodaFront/View/JobContent/JobTrend.action',
  historyList: '/dcfront/history/historyList',
} as const;

export type DodaSiteUrlValue = ValueOf<typeof DODA_SITE_URL>;

export type DodaSitePageName = keyof typeof DODA_SITE_URL;

/**
 * レコメンド URL
 */
export const RECOMMEND_LIST_URL = {
  Ranking_view_hopeL_location_income_new: '/DodaFront/View/MatchJobList.action',
  User_Entry_New: '/kyujin/recommend/User_View_OcL.html',
  AWS_Recommend_oubo: '/kyujin/recommend/ranking2.html',
  User_Entry: '/kyujin/recommend/User_View_OcL.html',
  User_View_New: '/kyujin/recommend/User_View_OcL.html',
  AWS_Recommend_etsuran: '/kyujin/recommend/ranking2.html',
  User_View: '/kyujin/recommend/User_View_OcL.html',
  view_list: '/DodaFront/View/JobHistoryList.action',
  bookmark_list: '/dcfront/bookmark/bookmarkList/',
  ranking_ocl_income: '/kyujin/recommend/ranking1.html',
  ranking_ocl: '/kyujin/recommend/ranking1.html',
  ranking_all: '/kyujin/recommend/ranking3.html',
  AWS_recommend: '/DodaFront/View/IntentionJobList.action',
  WelcomeMatch: '/dcfront/bookmark/welcomeMatchList/',
  newjob_list: '/DodaFront/View/NewJobList.action',
  WelcomeMatch_Obodash: '/kyujin/recommend/applicants_welcome_dash.html',
  WelcomeMatch_Obodash_a: '/kyujin/recommend/applicants_welcome_dash.html',
  WelcomeMatch_Obodash_b: '/kyujin/recommend/applicants_welcome_dash.html',
  Recommend_willcan: '/kyujin/recommend/ranking2.html',
  B4R_willcan: '/kyujin/recommend/ranking2.html',
  kensaku_list: '',
};

export type RecommendTypes = keyof typeof RECOMMEND_LIST_URL;
